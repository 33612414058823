<template>
    <div>
        <AboutNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Engineering</h1>

            <h4>The CDI Engineering team is committed to making your experience fast, easy and accurate!</h4>

            <img :src="`${$cdiVars.BASE_URL}/images/v3/engineering.jpg`" class="img-responsive img_content_right">

            <p>CDI's Engineering Department has extensive experience and is ready to assist you with your curb needs. Whether you have a standard application or something completely unique, we are ready to listen and help. We pride ourselves in being able to create solutions for your most challenging projects.</p>

            <p>We can always be reached to answer your questions and apply our expertise to your project. We don't stop when the whistle blows. Day, night and even weekends, our engineering team is there to support our production and our customers. We will do all that we can to help you meet your design needs and meet your project schedule! 1-888-234-7001</p>
        </main>
    </div>
</template>

<script>
import AboutNav from '@/components/content/AboutNav.vue'
export default {
    name: "About",
    components: {
        AboutNav,
    },
    created() {
        this.$appStore.setTitle(['CDI\'s Engineering Department']);
    },
}
</script>

<style scoped>

</style>